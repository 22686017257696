import { Stringish } from "../utils/format";

interface IContract {
    name:string,
    address:string,
    ticker?:string,
    logoUri?:string
}

interface IChainInfo {
    chainId:number,
    chainName:string,
    scanUrl?:string,
    tradeUrl?:string,
    contracts:IContract[],
    chainLogoUri:string
}



export function getChainLogoUri(chainId:number):Stringish {
    return chains.find(c => c.chainId === chainId)?.chainLogoUri;
}

export function getContractAddress(chainId:number, contractName:string):Stringish {
    return chains.find(c => c.chainId === chainId)?.contracts.find(a => a.name === contractName)?.address;
}

export function getChain(chainId:number):IChainInfo {
    return chains.find(c => c.chainId === chainId)!;
}

export function getContractInfo(chainId:number, contractName:string) {
    const contract = chains.find(c => c.chainId === chainId)?.contracts.find(a => a.name === contractName);
    const ticker = contract && contract.ticker;
    const logoUri = contract && contract.logoUri;
    return [ticker, logoUri] as const;
}

const chains:IChainInfo[] = [
    {
        chainId:369,
        chainName:"PulseChain",
        scanUrl: "https://scan.pulsechain.com",
        tradeUrl: "https://app.pulsex.com",
        chainLogoUri: "TODO",
        contracts:[ 
            {
                name:"token",
                address: "0x2f28EC133bd2861a166d375145513EEd7F4947B4"
            },
            {
                name:"poolMgmt",
                address: "0x0D092ef2cE72A93A07Ba94d72Ba3BF51bE627d69"
            },
            {
                name:"mAddress",
                address: "0x6eE9af985a3151F96DefCFA72893548271d0a218"
            },
            {
                name:"secretMath",
                address: "0xE591f9776FdDEDcfE279AA747F7C50943C8fBA53"
            },
            {
                name:"wpls",
                address: "0xA1077a294dDE1B09bB078844df40758a5D0f9a27"
            },
            {
                name:"uniswapPoolV2",
                address: "0x4cB433C1d6b03d958f1C6138575EEB0C5Cd9d1C1"
            },
            {
                name:"uniswapRouterV2",
                address: "0x98bf93ebf5c380C0e6Ae8e192A7e2AE08edAcc02"
            }
            //CONTRACTS_MANAGER
            //0xf478E794039D413Fbf8328f3E4A5B4c90D727Fb5
            ]
    },
    {
        chainId:943,
        chainName:"PulseChain Testnet",
        scanUrl: "https://scan.v4.testnet.pulsechain.com",
        tradeUrl: "https://app.v4.testnet.pulsex.com",
        chainLogoUri: "TODO",
        contracts:[ 
            {
                name:"token",
                address: "0x3e9e55FBE8300a673cc384bBCA38D201eAD4aD56"
            },
            {
                name:"poolMgmt",
                address: "0xF962e889a18089583CbD497e4C7388De0966410f"
            },
            {
                name:"mAddress",
                address: "0x00e4E43Ac5E8BBc1d923780265Cc8472d0D988Fc"
            },
            {
                name:"secretMath",
                address: "0x3a8Da7f0cd308d464d817Ec5dA7b3723C9191A35"
            },
            {
                name:"wpls",
                address: "0x70499adEBB11Efd915E3b69E700c331778628707"
            },
            {
                name:"uniswapPoolV2",
                address: "0xF6755b539f0b5bA94Ebc0680BfF3c2feEE4708f3"
            },
            {
                name:"uniswapRouterV2",
                address: "0xDaE9dd3d1A52CfCe9d5F2fAC7fDe164D500E50f7"
            }
            ]
    }
]