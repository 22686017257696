import abi from '../abis/wpls.json';
import { useRead2 } from './base/useRead';
import { getContractAddress } from '../config/contracts';
import { ChainContext } from '../../App';
import { useContext } from 'react';


function useRead(methodName:string, args:any[] = [])
{
    const { currentChainId, } = useContext(ChainContext);
    const address = getContractAddress(currentChainId, "wpls")!;
    return useRead2(currentChainId, address, abi, methodName, args);
}

export function useWplsBalanceOf(address:string) {
    return useRead("balanceOf", [address]);
}