import { Route, Routes } from "react-router-dom";
import { Home } from "../pages/Home";
import { Stats } from "../pages/Stats";

export const Body = () => {

    return (
        <>
           <Routes>
                <Route path="stats" element={<Stats/>}/>
                <Route path="/" element={<Home/>}/>
            </Routes>
        </>
    );
}