import { formatUnits, parseUnits } from "@ethersproject/units";
import { Falsy } from "@usedapp/core";
import { BigNumber, BigNumberish } from "ethers";


export type Stringish = string | undefined;


export function formatValue(number:BigNumber|undefined, units?:number):string|undefined {
    let result = number ? formatUnits(number, units ? units : 18) : undefined;
    let parsed = result && result.replace(/\.0+$/, '');
    return parsed;
}

export function parseValue(value:string|undefined):BigNumber|Falsy {
    return value === "NaN" || value === undefined ? undefined : parseUnits(value);
}

export function roundNumberFormatted(number:number):string {
    if(number < 1) {
        return number.toFixed(6);
    }
    else {
        let parsed = number.toLocaleString('en-US', { minimumFractionDigits: 2 })
        if(parsed.endsWith(".00")) parsed = parsed.substring(0, parsed.length-3);
        return parsed;
    }
}

export function divideBigNumbers(numerator:BigNumber, denominator:BigNumber):number {
    if(denominator === BigNumber.from(0)) return 0;
    
    const n1:number = Number(formatValue(numerator, 18));
    const n2:number = Number(formatValue(denominator, 18));

    return (n1/n2);
}



export function formatCurrencyDisplay(value:string|undefined, separator:string) {
    if(!value || value === "" || value === "undefined") return "";
    let numData = value.split(".");

    
    numData[0] = numData[0].split("").reverse().map((c, i) => i % 3 === 0 && i < numData[0].length && i > 0 ? c + separator : c).reverse().join("");
    if(numData.length === 2)
    {
        
        //numData[1] = numData[1].split("").map((c, i) => i % 3 === 0 && i < numData[1].length && i > 0 ? separator + c : c).join("");

        return numData[0] + "." + numData[1];
    }

    return numData[0];
}

export function formatValueDisplay(value: BigNumberish | Falsy) {

    
    if((!value || value === "NaN") && value !== 0) return ["NaN", ""] as const;
    // Make sure wei is a big number (convert as necessary)

    if(isValueLessThan(value, 0)) {
        return ["NaN", ""] as const;
    }
    
    if(isValueLessThan(value, 1000)) {
        return [value.toString(), ""] as const; 
    }
    if(isValueLessThan(value, 1000000)) {
        return [divideValueByAmount(value, 1000).toString(), "k"] as const; 
    }
    if(isValueLessThan(value,1000000000)) {
        return [divideValueByAmount(value,1000000).toString(), "M"] as const; 
    }

    if(isValueLessThan(value,1000000000000)) {
        return [divideValueByAmount(value,1000000000).toString(), "B"] as const; 
    }
    
    
    return [divideValueByAmount(value,1000000000000).toString(), "T"] as const; 
}

export default function formatNumber(number:BigNumber|undefined):number {
    return number ? number.toNumber() : NaN;
}


export function roundNumber(number:any, decimals:number):string|null
{
    if(number === undefined || number == null || isNaN(number)){
        return null;
    }

    if(decimals<=0 || BigNumber.isBigNumber(number))
    {
        return number.toString();
    }

    if(typeof(number) =="number")
    {
        return number.toFixed(decimals);
    }

    if(typeof(number) =="string")
    {
        var decimalRegex = /([.])/g; 
        if(!number.match(decimalRegex)){
            return number;
        }
        let split = number.split('.');

        let res = split[0]+(decimals>0? ".":"")+split[1].substr(0, decimals);
        return res;
    }

    return null;

}


function divideValueByAmount(value:BigNumberish|Falsy, amount:number):BigNumber|string|number {

    if(BigNumber.isBigNumber(value)) {
        return value.div(amount);
    }

    if(typeof(value) === "string") {
        let parsedValue = parseValue(value);
        if(!parsedValue) return "NaN";
        let formattedValue = formatValue(parsedValue.div(amount));
        return formattedValue ? formattedValue : "NaN";
    }

    if(typeof(value) === "number") {
        return value / amount;
    }

    return "NaN";
}

function isValueLessThan(value:BigNumberish|Falsy, amount:number):boolean {
    if(BigNumber.isBigNumber(value)) {
        return value.lt(BigNumber.from(amount));
    }

    if(typeof(value) === "string") {
        let parsedValue = parseValue(value);
        let parsedAmount = parseValue(amount.toString());
        if(!parsedValue || !parsedAmount) return false;
        return parsedValue.lt(parsedAmount);
    }

    if(typeof(value) === "number") {
        return value < amount;
    }

    return false;
}

