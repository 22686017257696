import { Button, Text } from "@chakra-ui/react"
import { useEthers } from "@usedapp/core"

export const ConnectButton = () => {

    const { account, activateBrowserWallet } = useEthers()
    const formattedAccount = account && formatString(account)

    return <>
        {formattedAccount && <Text mx="1rem" style={{color:"#000", border: "1px solid #252525", fontFamily: "StyreneALC", fontSize:"1.5rem", padding: "0.3rem 0.8rem", borderRadius:"7rem", background: "#00FF85"}}>{formattedAccount}</Text>}
        {!formattedAccount && <Button className="button" h="3rem" w="12rem" mx="1rem" onClick={() => activateBrowserWallet()} _hover={{backgroundColor:"#eeeeee", color:"#140432"}}>Connect</Button>}
    </>
}

function formatString(str:string) {
    if (str.length <= 6) {
      return str;
    } else {
      return str.slice(0, 3) + ".." + str.slice(-3);
    }
  }