import abi from '../abis/uniswapPool.json';
import { useRead2 } from './base/useRead';
import { BigNumber } from 'ethers';
import { getContractAddress } from '../config/contracts';
import { Stringish, divideBigNumbers, formatValue } from '../utils/format';
import { ChainContext } from '../../App';
import { useContext } from 'react';


function useRead(methodName:string, args:any[] = [])
{
    const { currentChainId, } = useContext(ChainContext);
    const address = getContractAddress(currentChainId, "uniswapPoolV2")!;
    return useRead2(currentChainId, address, abi, methodName, args);
}

export function usePoolReservesOrdered(contractName:string):BigNumber[] {
    const { currentChainId, } = useContext(ChainContext);
    const reserves = usePoolReserves();
    const token0 = useToken0();

    const address = getContractAddress(currentChainId, contractName);

    if(!token0 || !reserves) return [];

    return token0 === address ? [reserves[0], reserves[1]] : [reserves[1], reserves[0]];
}

export function usePoolTokenPrice(contractName:string):number {
    const reserves = usePoolReservesOrdered(contractName);
    if(reserves && reserves.length === 2 && reserves[0].gt(0)) {
        return divideBigNumbers(reserves[1], reserves[0]);
    }
    return 0;
}

export function usePoolReserves():BigNumber[] {
    return useRead("getReserves");
}

export function useToken0():string {
    return useRead("token0");
}

export function useLPTotalSupply():BigNumber {
    return useRead("totalSupply");
}

export function useLPBalanceOf(address:Stringish):BigNumber {
    return useRead("balanceOf", [address]);
}

