import abi from '../abis/poolMgmtHooks.json';
import { useRead2 } from './base/useRead';
import { getContractAddress } from '../config/contracts';
import { ChainContext } from '../../App';
import { useContext } from 'react';


function useRead(methodName:string, args:any[] = [])
{
    const { currentChainId, } = useContext(ChainContext);
    const address = getContractAddress(currentChainId, "poolMgmt")!;
    return useRead2(currentChainId, address, abi, methodName, args);
}

export function useAvgPoolDepth() {
    return useRead("avgPoolDepth");
}

export function useTokensForPoolAccounting() {
    return useRead("tokensForPoolAccounting", []);
}

export function useErc20ForPoolAccounting() {
    return useRead("erc20ForPoolAccounting", []);
}

export function usePoolErc20Mean() {
    return useRead("poolErc20Mean", []);
}

export function usePoolTokenMean() {
    return useRead("poolTokenMean", []);
}
