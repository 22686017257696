import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './ui/styles/styles.css'

import reportWebVitals from './reportWebVitals';
import { Config, DAppProvider, DEFAULT_SUPPORTED_CHAINS } from '@usedapp/core';
import * as serviceWorker from "./serviceWorker";
import { App } from './App';
import { PulseChain, PulseChainTestnet } from './blockchain/config/customChains';

const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)

const config: Config = {
  readOnlyChainId : 369,
  readOnlyUrls: {
    // 943: "https://rpc.v4.testnet.pulsechain.com",
    369:'https://rpc.pulsechain.com',
  },
  networks: [...DEFAULT_SUPPORTED_CHAINS, PulseChain, PulseChainTestnet]
}

root.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <App />
    </DAppProvider>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
