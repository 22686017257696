import { Chain } from "@usedapp/core"

export const PulseChain: Chain = {
    chainId: 369,
    chainName: 'PulseChain',
    isTestChain: false,
    isLocalChain: false,
    multicallAddress: '0xdc6ed7C2EE5ab7fd303334B34F7524Cbb463DE57',
    rpcUrl: 'https://rpc.pulsechain.com',
    nativeCurrency: {
      name: 'PLS',
      symbol: 'PLS',
      decimals: 18,
    },
    getExplorerAddressLink: (address: string) => `https://scan.pulsechain.com/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) => `https://scan.pulsechain.com/tx/${transactionHash}`,
  }
  
  export const PulseChainTestnet: Chain = {
    chainId: 943,
    chainName: 'PulseChainTestnet',
    isTestChain: true,
    isLocalChain: false,
    multicallAddress: '0x0E5A2F354a68A974c72BbB404E03c2Da0aC8a0B0',
    getExplorerAddressLink: (address: string) => `https://scan.v4.testnet.pulsechain.com/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) => `https://scan.v4.testnet.pulsechain.com/tx/${transactionHash}`,
    rpcUrl: 'https://rpc.v4b.testnet.pulsechain.com',
    blockExplorerUrl: 'https://scan.v4.testnet.pulsechain.com',
    nativeCurrency: {
      name: 'PulseChainTestnet',
      symbol: 'tPLS',
      decimals: 18,
    }
  }