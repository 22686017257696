import abi from '../abis/token.json';
import { useRead2 } from './base/useRead';
import { BigNumber } from 'ethers';
import { getContractAddress } from '../config/contracts';
import { Stringish, divideBigNumbers } from '../utils/format';
import { ChainContext } from '../../App';
import { useContext } from 'react';
import { useWrite2 } from './base/useWrite';


function useRead(methodName:string, args:any[] = [])
{
    const { currentChainId, } = useContext(ChainContext);
    const address = getContractAddress(currentChainId, "token")!;
    return useRead2(currentChainId, address, abi, methodName, args);
}

function useWrite(methodName:string)
{
    const { currentChainId, } = useContext(ChainContext);
    const address = getContractAddress(currentChainId, "token")!;
    return useWrite2(currentChainId, address, abi, methodName);
}

export function useTotalSupply():BigNumber {
    return useRead("totalSupply");
}

export function useGetRate():BigNumber {
    return useRead("_getRate");
}

export function useTotalFees():BigNumber {
    return useRead("totalFees");
}


export function useBalanceOf(account:Stringish):BigNumber {
    return useRead("balanceOf", [account]);
}

export function useHoldingLimitPercentage():number {
    const holdingLimit = useRead("holdingLimitNum");
    const amount = divideBigNumbers(holdingLimit, BigNumber.from(1000));

    return Math.round(amount*100);
}

export function useTransfer() {
    return useWrite("transfer");
}

