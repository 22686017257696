import { HStack, Link, Image, Tooltip, SimpleGrid } from "@chakra-ui/react"
import pulsechainLogo from "../../assets/images/scan-pulsechain.svg";
import pulseXLogo from "../../assets/images/pulsex-logo.png";
import dexScreenerLogo from "../../assets/images/dexscreenerlogo.svg";
import dextoolsLogo from "../../assets/images/dextools_logo.png";
import twitterLogo from "../../assets/images/twitter-logo.png";
import dappRadarLogo from "../../assets/images/dappradarlogo.svg";
import { ChainContext } from "../../App";
import { useContext } from "react";
import { useEthers } from "@usedapp/core";
import { getChain, getContractAddress } from "../../blockchain/config/contracts";
import { FaTelegramPlane } from "react-icons/fa";


export const Links = () => {

    const { currentChainId, } = useContext(ChainContext);
    const chain = getChain(currentChainId);
    const tokenAddress = getContractAddress(currentChainId, "token")!;
    
    
    const scanAddress = `${chain.scanUrl!}/address/${tokenAddress}`;
    const tradeAddress = `${chain.tradeUrl!}/info/token/${tokenAddress}`;
    const priceAddress = `https://dexscreener.com/pulsechain/${tokenAddress}`;
    const dextoolsAddress = `https://www.dextools.io/app/en/pulse/pair-explorer/0x4cb433c1d6b03d958f1c6138575eeb0c5cd9d1c1`;

    
    

    return <>
        <SimpleGrid spacing="2rem" columns={[3,6]}>
                    <Link href={scanAddress} isExternal>
                        <Tooltip label="Contract">
                            <Image src={pulsechainLogo} w="2rem" h={"auto"}/>
                        </Tooltip>
                    </Link>
                    <Link href={tradeAddress} isExternal>
                        <Tooltip label="Trade">
                            <Image src={pulseXLogo} w="1.5rem" h={"auto"}/>
                        </Tooltip>
                    </Link>
                    <Link href={dextoolsAddress} rel="noreferrer" isExternal>
                        <Tooltip label="DEXTools">
                            <Image src={dextoolsLogo} w="2.2rem" h={"auto"}/>
                        </Tooltip>
                    </Link>
                    <Link href={priceAddress} isExternal>
                        <Tooltip label="Chart">
                            <Image src={dexScreenerLogo} w="1.6rem" h={"auto"}/>
                        </Tooltip>
                    </Link>
                    {/* <Link href="#" isExternal>
                        <Tooltip label="Info">
                            <Image src={dappRadarLogo} w="2rem" h={"auto"}/>
                        </Tooltip>
                    </Link> */}
                    <Tooltip label="Telegram">
                    <Link href="https://t.me/PulseMoonR" isExternal color="#F2F2F2">
                        
                            <FaTelegramPlane style={{width: "2rem", height:"2rem"}}  />
                        
                    </Link>
                    </Tooltip>
                    <Link href="https://twitter.com/PulseMoonR" isExternal mt="0.3rem">
                        <Tooltip label="Twitter">
                            <Image src={twitterLogo} w="1.6rem" h={"auto"}/>
                        </Tooltip>
                    </Link>
                </SimpleGrid>
    </>
}