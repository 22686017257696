import abi from '../abis/mkt.json';
import { useRead2 } from './base/useRead';
import { getContractAddress } from '../config/contracts';
import { ChainContext } from '../../App';
import { useContext } from 'react';
import { BigNumber } from 'ethers';


function useRead(methodName:string, args:any[] = [])
{
    const { currentChainId, } = useContext(ChainContext);
    const address = getContractAddress(currentChainId, "mAddress")!;
    return useRead2(currentChainId, address, abi, methodName, args);
}

export function useTokensForMktAccounting():BigNumber {
    return useRead("tokensForMktAccounting");
}