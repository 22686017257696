import './App.css';
import './ui/styles/fonts/Roboto/Roboto-Regular.ttf';
import './ui/styles/fonts/Lato/Lato-Regular.ttf';
import './ui/styles/fonts/Superfine/Superfine-Regular.otf';

import './ui/styles/fonts/Cendro/CENDRO.ttf';
import './ui/styles/fonts/Styrene/StyreneALC-Regular.otf';
import './ui/styles/fonts/SpaceGrotesk/SpaceGrotesk-Regular.otf';

import theme from './ui/styles/theme';
import { Box, ChakraProvider } from '@chakra-ui/react';
import { HashRouter } from 'react-router-dom';
import {Header} from './ui/components/Header';
import {Body} from './ui/components/Body';
import {Footer} from './ui/components/Footer';
import { createContext, useState } from 'react';

export const ChainContext = createContext({
  currentChainId: 369,
  setCurrentChainId: (chainId:number) => {}
});

export function App() {

  const [currentChainId, setCurrentChainId] = useState(369);
  const value = { currentChainId: currentChainId, setCurrentChainId: setCurrentChainId };

  

  
  return ( <ChakraProvider theme={theme}>
        <Box id="page-background"></Box>
        <Box id="main" px={["1rem", "2rem"]} width="100%">
        
          <ChainContext.Provider value={value}>
            <HashRouter>
              <Header></Header>
              <Body></Body>
              <Footer></Footer>
            </HashRouter>
          </ChainContext.Provider>
          
        </Box>
      </ChakraProvider>
   
  ); 
}


