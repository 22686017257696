import abi from '../abis/secretMath.json';
import { useRead2 } from './base/useRead';
import { getContractAddress } from '../config/contracts';
import { ChainContext } from '../../App';
import { useContext } from 'react';



function useRead(methodName:string, args:any[] = [])
{
    const { currentChainId, } = useContext(ChainContext);
    const address = getContractAddress(currentChainId, "secretMath")!;
    return useRead2(currentChainId, address, abi, methodName, args);
}


export function useMathStartingPoolDepth() {
    return useRead("startingPoolDepth");
}

export function useMathTargetPoolDepth() {
    return useRead("TargetPoolDepth");
}
