import {   HStack, VStack,  Spinner, Box, Tooltip} from '@chakra-ui/react';
import { Falsy } from '@usedapp/core/dist/esm/src/model/types';
import { BigNumberish} from '@ethersproject/bignumber';
import Text from "../text/Text";
import { formatCurrencyDisplay, formatValueDisplay, roundNumber } from '../../../blockchain/utils/format';

export interface INumberDisplay
{
    header?:string
    amount:BigNumberish|Falsy|number,
    decimalsToDisplay?:number|undefined,
    unit?:string|undefined,
    unitColor?:string|undefined,
    headerSize?:string,
    textSize?:string
    unitsSize?:string,
    headerWeight?:string,
    numberWeigth?:string,
    unitSize?:string,
    noDisplay?:boolean,
}

export function NumericDisplay(props:INumberDisplay)
{
    const [amount, units] = (props.decimalsToDisplay===undefined)?
                                     [props.amount, undefined]:
                                     formatValueDisplay(props.amount);

    
    const amountToDisplay =  roundNumber(amount, (props.decimalsToDisplay ? props.decimalsToDisplay : 0));   
    return(
        <> 
            <VStack spacing="0.9rem" alignItems="center" mb="1rem">

                <HStack w="100%" alignItems="baseline" spacing={["0","0","0.1rem"]}>
                    {!amountToDisplay || amountToDisplay==="NaN" ? <Spinner/> :
                       
                        <Text fontSize={props.textSize ? props.textSize : ["2xl","3xl","4xl","5xl"]} as="span" fontWeight={props.numberWeigth || "extrabold"}>{props.noDisplay? "-":amountToDisplay? amountToDisplay: amount?.toString()}</Text>
}
                        {!amountToDisplay || props.noDisplay ? <></> : units && <Text fontSize={props.unitsSize ? props.unitsSize : ["1xl","1xl","2xl","3xl"]} as="span">{units}</Text>}
                    {props.unit && !props.noDisplay && <Text ml={2} as="span" fontSize={props.unitSize ? props.unitSize : ["small","medium","large"]} color={props.unitColor}>{props.unit}</Text>}
                </HStack>

                <HStack>
                    {props.header && <Text fontSize="0.85rem">
                        {props.header}
                    </Text>}
                </HStack>
            </VStack>
        </>
    )
}