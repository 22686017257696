import { Center, VStack, ListItem, List, SimpleGrid, Collapse, Button, Flex, Icon, Link, Box } from "@chakra-ui/react";
import Heading from "../components/text/Heading";
import Text from "../components/text/Text";

import BlackBox from "../components/display/BlackBox";
import { NumericDisplay } from "../components/display/NumericDisplay";

import { OkText } from "../components/text/OkText";
import { Links } from "../components/Links";
import { useBalanceOf, useTotalFees, useTotalSupply } from "../../blockchain/hooks/TokenHooks";
import { formatValue, roundNumber } from "../../blockchain/utils/format";
import { usePoolReservesOrdered, usePoolTokenPrice } from "../../blockchain/hooks/PoolHooks";
import { TransferToKnownWallet } from "../components/home/TransferToKnownWallet";
import { useTokensForPoolAccounting } from "../../blockchain/hooks/PoolMgmtHooks";
import { useContext, useState } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { ChainContext } from "../../App";
import { getChain, getContractAddress } from "../../blockchain/config/contracts";


export const Home = () => {

    const { currentChainId, } = useContext(ChainContext);
    const chain = getChain(currentChainId);
    const tokenAddress = getContractAddress(currentChainId, "token")!;

    const deadAddress = "0x000000000000000000000000000000000000dEaD";
    const deadAddress2 = "0x0000000000000000000000000000000000000369";

    const totalFees = useTotalFees();

    const poolReserves = usePoolReservesOrdered("token");

    const pooledValue = (poolReserves && poolReserves.length === 2) ? poolReserves[1] : undefined;

    const totalSupply = useTotalSupply();
    const tokenPrice = usePoolTokenPrice("token");
    const burnedSupply = useBalanceOf(deadAddress);
    const communityBurned = useBalanceOf(deadAddress2);
    const circulating = (totalSupply && burnedSupply && communityBurned) && totalSupply.sub(burnedSupply).sub(communityBurned);
    const totalBurned = (burnedSupply && communityBurned) && burnedSupply.add(communityBurned);
    const tokensForPoolAccounting = useTokensForPoolAccounting();

    const tradeAddress = `${chain.tradeUrl!}/info/token/${tokenAddress}`;


    const [expandedStats, setStatsExpanded] = useState(false);
    const toggleAdvancedCollapse = () => {
        setStatsExpanded(!expandedStats);
      };

    const [expandedHowToBuy, setExpandedHowToBuy] = useState(false);
    const toggleHowToBuyCollapse = () => {
        setExpandedHowToBuy(!expandedHowToBuy);
      };


    return <>
        <VStack spacing={["4rem","6rem", "8rem"]}>
            <VStack mt={["4rem","6rem","8rem"]} spacing="4rem">
                <VStack>
                    <Heading bgClip='text' className="text-glassy" textTransform="none" bgGradient='linear(to-r, #FE0113, #E219E4, #463AFE, #00E0F7)' fontSize={["2rem", "4rem", "6rem", "8rem"]} lineHeight={["2rem", "4rem", "6rem", "8rem"]}>PulseMoonR</Heading>
                    <VStack spacing={"1.4rem"}>
                        <Text fontSize={["0.8rem","1.1rem","1.3rem"]} textTransform="uppercase" fontWeight="bold" letterSpacing="0.1rem">
                            Reflections on Steroids
                        </Text>
                        <Links></Links>

                        <Button fontSize="1rem" fontWeight="Bold" borderWidth="2px" borderColor="#00E0F7" onClick={toggleHowToBuyCollapse} 
                        bgColor="#eee" color="#140432" _hover={{ color:"#eee", background:"#140432" }}>
                            <Flex justifyContent="space-between" alignItems="center">
                                How To Buy
                                <Icon as={ChevronDownIcon} boxSize={4} />
                            </Flex>
                        </Button>
                        <Collapse in={expandedHowToBuy} animateOpacity>
                        <BlackBox borderRadius="10px" p="2rem" minW={["100%","32rem","34rem","36rem"]}>
                        
                                <List spacing={3}>
                                    <ListItem>
                                        <OkText color="#00E0F7">First, buy PulseChain's native token $PLS on <Link href="https://www.okx.com/trade-spot/pls-usdt" isExternal color="#00E0F7" textDecoration="underline">OKX exchange</Link></OkText>
                                    </ListItem>
                                    <ListItem>
                                        <OkText color="#00E0F7">Then, transfer your $PLS tokens to your wallet. Learn how <Link href="https://www.okx.com/support/hc/en-us/articles/11631709295117-How-to-easily-withdraw-and-deposit-crypto-between-OKX-Wallet-and-Exchange-account" isExternal color="#00E0F7" textDecoration="underline">here</Link></OkText>
                                    </ListItem>                                    
                                    <ListItem>
                                        <OkText color="#00E0F7">Finally, go to PulseX to <Link href={tradeAddress} isExternal color="#00E0F7" textDecoration="underline">swap your $PLS for $MOONR</Link></OkText>
                                    </ListItem> 
                                    <ListItem>
                                        <OkText color="#00E0F7">On PulseX, make sure to increase your slippage to 4% or more</OkText>
                                    </ListItem>
                                </List>
                                
                                <Center>

                                <Box textAlign="center" maxW="25rem"  mt="3rem">
                                        <Text fontSize="0.9rem" lineHeight="0.9rem">If you don't know how to use PulseX or how to set your slippage, <Link href="https://www.youtube.com/watch?v=vtqE7c_BM40" isExternal color="#00E0F7" textDecoration="underline">watch this tutorial</Link>. You can also join us on telegram for instant support.</Text>
                                        
                                    </Box>
                                </Center>
                                    
                                
                            </BlackBox>
                        </Collapse>
                    </VStack>
                </VStack>  

            </VStack>
            <SimpleGrid columns={[1,1,1,1,2]} spacing={"4rem"}>
            <VStack spacing="1.875rem">
                <Heading fontSize={["1.5rem", "1.8rem", "2.125rem"]}>Stats</Heading> 
                <BlackBox borderRadius="10px" p="2rem" minH="30rem" minW={["100%","32rem","34rem","36rem"]}>
                <SimpleGrid spacing={["2rem","3rem","4rem","6rem"]} columns={[2,2,2]} pt="4rem">
                    <Center><NumericDisplay header="Price" amount={roundNumber(tokenPrice, 3)} unit="PLS" decimalsToDisplay={undefined}/></Center>
                    <Center><NumericDisplay header="Reflected" amount={formatValue(totalFees)} decimalsToDisplay={2} /></Center>
                    <Center><NumericDisplay header="Circulating" amount={formatValue(circulating)} decimalsToDisplay={2}/></Center>
                    <Center><NumericDisplay header="Burned" amount={formatValue(totalBurned)} decimalsToDisplay={2}/></Center>
                </SimpleGrid>
                <Button fontSize="1rem" fontWeight="light" borderColor="transparent" onClick={toggleAdvancedCollapse} mt="1rem" width="100%">
                <Flex justifyContent="space-between" alignItems="center">
                    Advanced
                    <Icon as={ChevronDownIcon} boxSize={4} />
                </Flex>
                </Button>
                <Collapse in={expandedStats} animateOpacity>
                    <SimpleGrid spacing={["2rem","3rem","4rem","6rem"]} columns={[2,2,2]} pt="2rem">
                            <Center><NumericDisplay header="Pool Mng'R" amount={formatValue(tokensForPoolAccounting)} decimalsToDisplay={2}/></Center>
                            <Center><NumericDisplay header="Liquidity" amount={formatValue(pooledValue)} decimalsToDisplay={1} unit="PLS"/></Center>
                    </SimpleGrid>
                </Collapse>
            </BlackBox>
            </VStack>
            <VStack spacing="1.875rem">
                <Heading fontSize={["1.5rem", "1.8rem", "2.125rem"]}>Why Buy</Heading> 
                <BlackBox borderRadius="10px" p="2rem" minH="30rem" minW={["100%","32rem","34rem","36rem"]}>
                    <List spacing="1.4rem">
                        <ListItem>
                        <OkText>No admin keys, pure community-driven control.</OkText>
                        </ListItem>
                        <ListItem>
                        <OkText>Reflections on steroids, turbocharged.</OkText>
                        </ListItem>
                        <ListItem>
                        <OkText>Liquidity added only when price rises.</OkText>
                        </ListItem>
                        <ListItem>
                        <OkText>Buyback and burns otherwise.</OkText>
                        </ListItem>
                        <ListItem>
                        <OkText>Trading makes price rise.</OkText>
                        </ListItem>
                        <ListItem>
                        <OkText>3% tax on buys and sells for fueling growth.</OkText>
                        </ListItem>
                        <ListItem>
                        <OkText>1.5% for liquidity and buybacks.</OkText>
                        </ListItem>
                        <ListItem>
                        <OkText>1% distributed to hodlers.</OkText>
                        </ListItem>
                        <ListItem>
                        <OkText>0.5% for project growth.</OkText>
                        </ListItem>
                    </List>
                </BlackBox>
            </VStack>      
            </SimpleGrid>
            <VStack spacing={["1.875rem"]} maxW="100%">
                <VStack spacing="0.5rem">
                    <Heading fontSize={["1.5rem", "1.8rem", "2.125rem"]}>Noise</Heading>
                    <Text>Make noise by transferring MOONR to known wallets!</Text>
                </VStack>
                <BlackBox borderRadius="10px" p="2rem" minH="27rem" minW={["100%","32rem","34rem","36rem"]} maxW={{base:"100%", md:"30rem"}}>
                    <TransferToKnownWallet></TransferToKnownWallet>
                </BlackBox>
            </VStack>

              
        </VStack>
    </>
}