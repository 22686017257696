import { ChainId, useCall } from "@usedapp/core";
import { Contract, ContractInterface } from "ethers";


export function useRead2(chainId:ChainId, contractAddress:string, abi:ContractInterface, methodName:string, args:any[]) {
    const { value, error } =
        useCall((contractAddress && !args.some(d => d === undefined || isNaN(d))) &&
          {
              contract: new Contract(contractAddress, abi),
              method: methodName,
              args: args,
          },
          {
              chainId:chainId, 
          }
        ) ?? {};
      
        if(error) {
        console.error(error.message)
        return undefined
      }
      return (value && value.length === 1) ? value[0]:value;
  }