import { BoxProps, HStack, Image, VStack } from "@chakra-ui/react";
import Heading from "../text/Heading";
import Text from "../text/Text";

export interface IKnownWallet extends BoxProps {
    image:string;
    name:string;
    description?:string;
    address:string;
    link:string;
    linkDescription:string;
}

export const KnownWallet = (props:IKnownWallet) => {

    return <>
    <VStack spacing={["1rem"]} alignItems="start">
        <HStack spacing={["1rem"]} alignItems="start">
            <Image src={props.image} borderRadius="50px" w="4rem"></Image>
            <VStack alignItems="start">
                <Heading fontSize={["1rem"]} lineHeight={["1rem"]}>{props.name}</Heading>
                {props.description && <Text>{props.description}</Text>}
            </VStack>
        </HStack>
    </VStack>
        
        
    </>
}