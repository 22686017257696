import { Box, BoxProps } from "@chakra-ui/react"



const BlackBox = (props:BoxProps) => {

    return <>
        <Box borderColor="#2A1D8F" borderRadius="60px" borderStyle="solid" backgroundColor="#140432" borderWidth="1px" {...props}>{props.children}</Box>
    </>
}

export default BlackBox