import { Link, SimpleGrid, Tooltip, VStack } from "@chakra-ui/react"
import Heading from "../components/text/Heading"
import Text from "../components/text/Text"
import { useContext } from "react";
import { ChainContext } from "../../App";
import { getChain, getContractAddress } from "../../blockchain/config/contracts";

export const Footer = () => {

    const { currentChainId, } = useContext(ChainContext);
    const chain = getChain(currentChainId);
    const tokenAddress = getContractAddress(currentChainId, "token")!;
    const poolMgmtAddress = getContractAddress(currentChainId, "poolMgmt")!;

    const tokenScanAddress = `${chain.scanUrl!}/address/${tokenAddress}`;
    const poolScanAddress = `${chain.scanUrl!}/address/${poolMgmtAddress}`;

    return <>
         <VStack mt="9rem" mb="8rem" spacing="2rem">
            <Heading fontSize="1.2rem" lineHeight="1.2rem">CONTRACTS</Heading>
            <VStack spacing="1rem">
                <VStack>
                    <Text fontSize="1rem" lineHeight="1rem">MOONR</Text>
                    <Tooltip label="MOONR Contract">
                        <Link href={tokenScanAddress} isExternal>
                            <Text fontSize="0.8rem" lineHeight="0.8rem">0x2f28EC133bd2861a166d375145513EEd7F4947B4</Text>
                        </Link>
                    </Tooltip>
                </VStack>
                
                <VStack>
                    <Text fontSize="1rem" lineHeight="1rem">POOL MGN'R</Text>
                    <Tooltip label="POOL MGN'R Contract">
                        <Link href={poolScanAddress} isExternal>
                            <Text fontSize="0.8rem" lineHeight="0.8rem">0x0D092ef2cE72A93A07Ba94d72Ba3BF51bE627d69</Text>
                        </Link>
                    </Tooltip>
                </VStack>
            </VStack>
            
        </VStack>
    </>
}