import {extendTheme} from "@chakra-ui/react";



const theme = extendTheme({
    styles: {
        global: {
          body: {
            bg: "rgb(8, 6, 11)",
            color: "#C5ECF7",
            fontFamily:"StyreneALC"
          },
        }
      },
      fonts: {
        heading: 'StyreneALC'
      },
      colors: {
        grey: "#252525",
        lightGrey: "#828282",
        highlighted: "#10CD00",
        black:"#000",
        brightGreen:"##00FF85"
      },
    components: {
      Select: {
        baseStyle: {
          field: {
            // background:"#000",
            
          }
        }
      },
      Input: {
        variants: {
          text: {
            field: {
              border: "1px solid #252525",
              borderRadius: "0.6rem",
              bg: "#000000",
              height:"3.4375rem",
              width:"20.875rem",
            },
            element: {
             color: "#828282",
             paddingRight:"1.25rem"
            }
          },
        },
        defaultProps: {
          variant: "text"
        }
      },
      Modal: {
        baseStyle: {
          dialog: {
            bg:"#000000",
            border: "1px solid #252525",
            borderRadius: "30px",
            py: "2.5rem",
            px: "1.875rem"
          },
          header: {
            pt: 0,
            pb: "1.875rem"
          },
          body: {
            pt: 0,
            pb: "1.875rem"
          },
          footer: {
            p: 0
          }
        }
      },
      Button: {
            baseStyle: {
                border: "1px solid #252525",
                fontFamily: "StyreneALC",
            },
            sizes: {
                lg: {
                  fontSize:"1.25rem",
                  py:"1rem",
                  px:"1.9rem",
                  borderRadius:"7rem"
                },
                md: {
                    fontSize:"1.5rem",
                    py:"0.5rem",
                    px:"1.25rem",
                    borderRadius:"7rem"
                },
                sm: {
                    fontSize:"1rem",
                    p:"1rem",
                    borderRadius:"5rem"
                },
                xs: {
                  fontSize: "0.8125rem",
                  px: "0.4375rem",
                  py: "1.2375rem",
                  borderRadius: "0.5rem",
                  fontWeight: "normal"
                }
            },
            defaultProps: {
                backgroundColor:"#000000",
                bgColor:"#000000",
                bg:"#000000",
                colorScheme: '#111',
                
              },
      },
      Text : {
        sizes : {
          lg: {
            fontSize: "1.5rem"
          },
          md: {
            fontSize: "1rem"
          },
          sm: {
            fontSize: "0.8125rem"
          },
          xs: {
            fontSize: "0.75rem"
          }
        }
      }
    },
}
);
export default theme;