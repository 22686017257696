import { Center, SimpleGrid, VStack } from "@chakra-ui/react"
import { NumericDisplay } from "../components/display/NumericDisplay"
import { useTokensForMktAccounting } from "../../blockchain/hooks/MAddressHooks"
import { formatValue, roundNumber } from "../../blockchain/utils/format";
import { useBalanceOf, useGetRate } from "../../blockchain/hooks/TokenHooks";
import Heading from "../components/text/Heading";
import { useMathStartingPoolDepth, useMathTargetPoolDepth } from "../../blockchain/hooks/SecretMathHooks";
import { useAvgPoolDepth, useErc20ForPoolAccounting, usePoolErc20Mean, usePoolTokenMean, useTokensForPoolAccounting } from "../../blockchain/hooks/PoolMgmtHooks";
import { useWplsBalanceOf } from "../../blockchain/hooks/WPlsHooks";
import { getContractAddress } from "../../blockchain/config/contracts";
import { ChainContext } from "../../App";
import { useContext } from "react";
import { BigNumber } from "ethers";
import { useLPBalanceOf, useLPTotalSupply, usePoolReservesOrdered, usePoolTokenPrice } from "../../blockchain/hooks/PoolHooks";

export const Stats = () => {

    const deadAddress = "0x000000000000000000000000000000000000dEaD";

    const { currentChainId } = useContext(ChainContext);

    const mktAccounting = useTokensForMktAccounting();
    //const knownAddress = "0x1720b3f672AE3811D14086B502Aab83aC4F4a302";
    // const knownAddress = "0xb1E0e51B6a47085697D58d638a6123F58f49DF5c";
    const knownAddress = "0x1133D8f0f87DAB2Ddc537374CC5838c504304C71";
    
    const poolMgmtAddress = getContractAddress(currentChainId, "poolMgmt")!;

    const balanceOfKnowAddress = useBalanceOf(knownAddress);

    const startingPoolDepth = useMathStartingPoolDepth();
    const avgPoolDepth = useAvgPoolDepth();
    const targetPoolDepth = useMathTargetPoolDepth();

    const tokensForPoolAccounting = useTokensForPoolAccounting();
    const erc20ForPoolAccounting = useErc20ForPoolAccounting();
    const wplsPoolMgmtBalance = useWplsBalanceOf(poolMgmtAddress);

    const poolReserves = usePoolReservesOrdered("token");



    const poolBusdMean = usePoolErc20Mean() as BigNumber;

    const poolTokenMean = usePoolTokenMean() as BigNumber;
    const priceAvg = (poolBusdMean && poolTokenMean) && roundNumber(Number(formatValue(poolBusdMean)) / Number(formatValue(poolTokenMean)), 8);
    const price = usePoolTokenPrice("token");

    const liquidity = (poolReserves && poolReserves.length === 2 && price) && Math.round(Number(formatValue(poolReserves[0])) * price) * 2;

    const lpDeadAddress = useLPBalanceOf(deadAddress);
    const lpTotalSupply = useLPTotalSupply();

    const reflectionsRate = useGetRate();


    return <>
        <VStack spacing="5rem" mt={["8.125rem", "6.25rem"]} w="100%">
            <VStack spacing="2rem" alignItems="start" w="100%">
                <Heading>Mkt</Heading>
                <SimpleGrid columns={[3]} spacing="2rem" w="100%">
                    <Center><NumericDisplay header="Mkt Accounting" amount={formatValue(mktAccounting)} decimalsToDisplay={6}/></Center>
                    <Center><NumericDisplay header="One Mkt Address" amount={formatValue(balanceOfKnowAddress)} decimalsToDisplay={6}/></Center>
                </SimpleGrid>
            </VStack>

            <VStack spacing="2rem" alignItems="start" w="100%">
                <Heading>Pool Depth Mgmt</Heading>
                <SimpleGrid columns={[3]} spacing="2rem" w="100%">
                    <Center><NumericDisplay header="Price Avg" amount={priceAvg} decimalsToDisplay={4}/></Center>
                    <Center><NumericDisplay header="Price" amount={price} decimalsToDisplay={4}/></Center>
                    <Center><NumericDisplay header="Starting Pool Depth" amount={formatValue(startingPoolDepth, 38)} decimalsToDisplay={1}/></Center>
                    <Center><NumericDisplay header="Avg Pool Depth" amount={Number(formatValue(avgPoolDepth, 38))} decimalsToDisplay={1}/></Center>
                    <Center><NumericDisplay header="Target Pool Depth" amount={formatValue(targetPoolDepth, 38)} decimalsToDisplay={1}/></Center>

                    {/* <Box>{formatValue(startingPoolDepth, 38)}</Box>
                    <Box>{formatValue(avgPoolDepth, 38)}</Box>
                    <Box>{roundNumber(formatValue(targetPoolDepth, 38), 2)}</Box> */}
                </SimpleGrid>
            </VStack>

            <VStack spacing="2rem" alignItems="start" w="100%">
                <Heading>Funds mgmt</Heading>
                <SimpleGrid columns={[3]} spacing="2rem" w="100%">
                    <Center><NumericDisplay header="Tokens for pool accounting" amount={formatValue(tokensForPoolAccounting)} decimalsToDisplay={6}/></Center>
                    <Center><NumericDisplay header="WPLS for pool accounting" amount={formatValue(erc20ForPoolAccounting)} decimalsToDisplay={6}/></Center>
                    <Center><NumericDisplay header="Pool contract WPLS balance" amount={formatValue(wplsPoolMgmtBalance)} decimalsToDisplay={6}/></Center>

                    
                </SimpleGrid>
            </VStack>
            <VStack spacing="2rem" alignItems="start" w="100%">
                <Heading>Pool</Heading>
                <SimpleGrid columns={[3]} spacing="2rem" w="100%">
                    <Center><NumericDisplay header="Liquidity" amount={liquidity} decimalsToDisplay={1} unit="PLS"/></Center>
                    <Center><NumericDisplay header="LP dead addr" amount={formatValue(lpDeadAddress)} decimalsToDisplay={1} unit="PLS"/></Center>
                    <Center><NumericDisplay header="LP total supply" amount={formatValue(lpTotalSupply)} decimalsToDisplay={1} unit="PLS"/></Center>
                    <Center><NumericDisplay header="MOONR Reserves" amount={formatValue((poolReserves && poolReserves.length === 2) ? poolReserves[0] : undefined)} decimalsToDisplay={1} unit="MOONR"/></Center>
                    <Center><NumericDisplay header="PLS Reserves" amount={formatValue((poolReserves && poolReserves.length === 2) ? poolReserves[1] : undefined)} decimalsToDisplay={1} unit="PLS"/></Center>
                    <Center><NumericDisplay header="Rate" amount={formatValue(reflectionsRate)} decimalsToDisplay={1} unit="PLS"/></Center>
                    
                </SimpleGrid>
            </VStack>
            
        </VStack>
        
    </>
}