import { Button, HStack, Link, Menu, MenuButton, MenuItem, MenuList, VStack, Image, Heading, Input, InputGroup, InputRightAddon, InputRightElement, useMediaQuery } from "@chakra-ui/react"
import knownWallets from "../../../blockchain/config/knownWallets.json"
import { KnownWallet } from "../display/KnownWallet"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { useState } from "react"
import Text from "../text/Text"
import { BiLinkExternal } from 'react-icons/bi'
import { useBalanceOf, useTotalSupply, useTransfer } from "../../../blockchain/hooks/TokenHooks"
import { divideBigNumbers, formatValue, parseValue, roundNumberFormatted } from "../../../blockchain/utils/format"
import { useEthers } from "@usedapp/core"
import { ConnectButton } from "../header/ConnectButton"
import { BigNumber } from "ethers"

export const TransferToKnownWallet = () => {

    const [isSmallScreen] = useMediaQuery("(max-width: 640px)");

    const {account} = useEthers();
    const [selectedPosition, setSelectedPosition] = useState(0);

    const [transferAmount, setTransferAmount] = useState<BigNumber>(BigNumber.from(0));

    const addressesExcludedFromFees = ["0x0000000000000000000000000000000000000369"];

    const scanAddress = "https://scan.pulsechain.com/address/";

    const selectedKnownAccount = knownWallets[selectedPosition].address;

    const [, transferSend] = useTransfer();

    const balanceOfWallet = useBalanceOf(selectedKnownAccount);

    const totalSupply = useTotalSupply();

    const isWalletMaxed = (totalSupply && balanceOfWallet && !addressesExcludedFromFees.includes(selectedKnownAccount)) && divideBigNumbers(balanceOfWallet, totalSupply) >= 0.1;

    const onTransferClick = () => {
        console.log(transferAmount);
        transferSend(selectedKnownAccount, transferAmount)
    }

    const onInputChange = (event:any) => {

        const value = event?.target?.value;

        const number = Number(value);

        const bigValue = parseValue(number.toString());
        
        if(bigValue) setTransferAmount(bigValue);
    }




    return <>
        <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} fontSize={["0.9rem", "1.5rem"]} borderColor="#eeeeee">
                Change Wallet
            </MenuButton>
            <MenuList backgroundColor="#010101" mr="2.5%" ml="2.5%" maxW={["100vw","20rem","25rem","30rem"]}>
                        {knownWallets.map((w, i) => { return <MenuItem key={i} backgroundColor="#010101" _hover={{backgroundColor:"#2A1D8F"}} p="2rem"  onClick={() => setSelectedPosition(i)}>
                                                <KnownWallet 
                                                    image={w.image} 
                                                    name={w.name} 
                                                    description={w.description} 
                                                    address={w.address} 
                                                    link={w.link} 
                                                    linkDescription={w.linkDescription} />
                                                </MenuItem> } ) }
            </MenuList>
            {selectedPosition !== -1 && <>
                <VStack maxW="90vw" spacing={["2rem","5rem"]}>
                    <VStack w="100%" spacing={["1rem"]} alignItems="start" mt={["2rem", "4rem"]}>
                        {isSmallScreen && <Image src={knownWallets[selectedPosition].image} borderRadius="50px" w="4rem"></Image>}
                        <HStack w="100%" spacing={["1rem"]} alignItems="start">
                            {!isSmallScreen && <Image src={knownWallets[selectedPosition].image} borderRadius="50px" w="4rem"></Image>}
                            <VStack w="100%" alignItems="start">
                                <Heading fontSize={["1rem"]} lineHeight={["1rem"]}>{knownWallets[selectedPosition].name}</Heading>
                                    {knownWallets[selectedPosition].description && <Text>{knownWallets[selectedPosition].description}</Text>}
                                        <VStack alignItems="start" pt={["0.75rem", "2rem"]}>
                                            <Text fontWeight="bold">Known Address</Text>
                                            <Link href={`${scanAddress}${knownWallets[selectedPosition].address}`} fontSize={["0.65rem","0.8rem","0.9rem","0.9rem"]} w="100%" display="flex" alignItems="center" isExternal>
                                                {knownWallets[selectedPosition].address}  <BiLinkExternal style={{ marginLeft: "0.2rem" }}/>
                                            </Link>
                                            </VStack>
                                            {knownWallets[selectedPosition].link !== "" ? <VStack alignItems="start" pt={["0.75rem", "2rem"]}>
                                                <Text fontWeight="bold">Proof Link</Text>
                                                 <Link href={knownWallets[selectedPosition].link} fontSize={["0.9rem"]} w="100%" display="flex" alignItems="center" isExternal>
                                                    {knownWallets[selectedPosition].linkDescription}
                                                </Link>
                                            </VStack> :
                                            <Text>
                                                {knownWallets[selectedPosition].linkDescription}
                                            </Text>}
                                        </VStack>
                                    </HStack>
                                </VStack>
                                
                                <VStack spacing="1rem">
                                    {balanceOfWallet && 
                                        <VStack>
                                            <Text fontWeight="bold">Holdings</Text>
                                            <HStack>
                                                <Text>
                                                    {roundNumberFormatted(Number(formatValue(balanceOfWallet)))}
                                                </Text>
                                                <Text>
                                                    MOONR
                                                </Text>
                                            </HStack>
                                        </VStack>}
                                    
                                    <VStack w="100%" spacing={["2rem"]}>
                                        <VStack alignItems="start">
                                        <Text pl="0.5rem">Amount to Transfer</Text>
                                        <InputGroup>
                                            <Input mb="-0.7rem" onChange={onInputChange} type="number" placeholder="0.0" w={["100%","20rem"]}></Input>
                                            <InputRightElement>
                                                <Text>MOONR</Text>
                                            </InputRightElement>
                                        </InputGroup>
                                    </VStack>
                                    <VStack w="100%">
                                        {account ? <Button borderRadius="20px" isDisabled={isWalletMaxed} borderWidth="1px" borderColor="#eeeeee" h="3rem" w={["100%","20rem"]} onClick={onTransferClick}
                                                bgGradient="linear(to-r, #FE0113, #E219E4, #463AFE, #00E0F7)" color="#140432"
                                                _hover={{ background:"#eee" }}>
                                                Make Noise
                                            </Button>
                                            : <ConnectButton></ConnectButton>}

                                        {isWalletMaxed && <Text color="red">Target wallet has over 1%</Text>}
                                    </VStack>
                                    
                                </VStack>
                                </VStack>
                                
                                
                            </VStack>
                            </>
                        }
                    </Menu>
        
</>

}